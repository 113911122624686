<template>
  <section class="archived-positions">
    <positions-skeleton v-if="isFetchingArchivedPositions" />

    <template v-else>
      <div class="archived-positions__heading">
        <div class="archived-positions__heading-title">
          <h2 class="archived-positions__heading-title-text">
            {{ $t(`${langPath}.title`) }}
          </h2>
        </div>

        <div class="archived-positions__filters">
          <app-searchable-select
            v-model="localEmployerIdArchivedFilter"
            class="archived-positions__employers-select"
            name="employerId"
            :items="employersList"
            data-test="employers-select"
            @input="emitArchivedPositionsFilterEvent"
          />
        </div>
      </div>

      <ul
        v-if="hasPositions"
        class="archived-positions__items"
      >
        <li
          v-for="position in archivedPositions"
          :key="position.id"
          class="archived-positions__item"
        >
          <position-card
            :position="position"
            :data-test-position-id="position.id"
            v-on="$listeners"
          />
        </li>
      </ul>

      <empty-state
        v-else
        class="archived-positions__empty-state"
        data-test="empty-state"
        :title="emptyStateTitle"
      />
      <section class="archived-positions__pagination">
        <div class="archived-positions__see-more">
          <ev-button
              v-if="hasNextPage"
              data-test="next-page-button"
              variant="tertiary"
              color="blue"
              @click="$emit('nextArchivedPositionsPage')"
              >
              {{ $t(`${langPath}.nextPage`) }}
          </ev-button>
        </div>
      </section>
    </template>
  </section>
</template>

<script>
import EmptyState from './components/EmptyState'
import PositionCard from './components/PositionCard'
import PositionsSkeleton
  from '@/components/Skeletons/PositionsSkeleton'
import EvButton from '@revelotech/everestV2/EvButton'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, POSITIONS, EMPLOYERS } from '@/store/namespaces'

const positionsHelper = createNamespacedHelpers(POSITIONS)
const employersHelper = createNamespacedHelpers(EMPLOYERS)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'ArchivedPositions',
  components: {
    EmptyState,
    PositionCard,
    PositionsSkeleton,
    EvButton
  },
  data () {
    return {
      langPath: __langpath,
      localEmployerIdArchivedFilter: null
    }
  },
  computed: {
    ...positionsHelper.mapState([
      'archivedPositions',
      'archivedPositionsPage',
      'employerIdArchivedFilter',
      'isFetchingArchivedPositions',
      'archivedPositionsTotalPages'
    ]),
    ...employersHelper.mapState(['employers']),
    ...currentUserHelper.mapState(['currentUser']),
    hasPositions () {
      return this.archivedPositions.length > 0
    },
    hasNextPage () {
      return this.archivedPositionsTotalPages > this.archivedPositionsPage
    },
    selectedEmployer () {
      return this.employers.find(
        employer => employer.id === this.localEmployerIdArchivedFilter
      )
    },
    emptyStateTitle () {
      if (this.localEmployerIdArchivedFilter === null) {
        return this.$t(`${this.langPath}.emptyState.title`)
      }

      return this.$t(
        `${this.langPath}.emptyState.titleWithEmployerName`,
        { employerName: this.selectedEmployer?.name }
      )
    },
    employersList () {
      const allPositionsOption = {
        value: null, label: this.$t(`${this.langPath}.filter.allPositions`)
      }

      return [{ items: [allPositionsOption, ...this.filteredEmployers] }]
    },
    filteredEmployers () {
      return this.employers
        .map((employer) => ({
          label: employer.name,
          value: employer.id
        }))
    }
  },
  async mounted () {
    if (this.archivedPositions.length === 0 && this.localEmployerIdArchivedFilter === null) {
      await this.getArchivedPositions()
    }
    await this.getEmployers()
  },
  created () {
    this.localEmployerIdArchivedFilter = this.employerIdArchivedFilter
  },
  methods: {
    ...employersHelper.mapActions(['getEmployers']),
    ...positionsHelper.mapActions(['getArchivedPositions']),
    emitArchivedPositionsFilterEvent (employerId) {
      if (employerId === null) {
        this.$emit('fetchArchivedPositions')
      } else {
        this.$emit('fetchArchivedPositionsByEmployer', employerId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.archived-positions {
  display: flex;
  flex-direction: column;
  margin-top: $base * 10;

  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-title {
      align-items: center;
      display: flex;

      &-text {
        font: $tx-title-2;
      }
    }
  }

  &__filters {
    display: flex;
    justify-content: flex-end;
  }

  &__items {
    display: flex;
    flex-flow: wrap;
    gap: $base * 6;
    margin-top: $base * 10;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
